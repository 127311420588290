import React from "react";
import PageLayout from "../../layouts/PageLayout";
import IconTitle from "../icons/IconTitle";
import * as style from "./PagePoste.module.scss";
import SideMenu from "../menus/SideMenu";
import { metiers_menu } from "../menus/MenuData";

class PagePoste extends React.Component {

  render() {

    let key_skills = {
      icon: "key_skills",
      iconTitle: "compétences clés:"
    }

    let prerequisites = {
      icon: "prerequisites",
      iconTitle: "pre-requis:"
    }

    let missions = {
      icon: "missions",
      iconTitle: "missions principales:"
    }

    let activities = {
      icon: "activities",
      iconTitle: "activités principales:"
    }
        
    return (
      <PageLayout {...this.props.header}>
        
        <div className={style.main_container}>
        <div className={style.container}>
          <div className={style.content} >  
            <div className={style.content_header}>
              <div className={style.descriptif_container}>
                <div className={style.descriptif} >
                  Descriptif du poste
                </div>
              </div>
              <div className={style.title}>
                {this.props.title}
              </div>
            </div>

            <div className={style.content_col1}>
              <div className={style.cream_bloc}></div>
              {this.renderBloc(key_skills, this.props.key_skills)}
              {this.renderBloc(prerequisites, this.props.prerequisites)}
            </div>
            <div className={style.content_col2}>
              {this.renderBloc(missions, this.props.missions)}
              {this.renderBloc(activities, this.props.activities)}
            </div>


          </div>
          <div className={style.side_menu} >
            <SideMenu {...metiers_menu}/>
          </div>        
        </div>
        </div>
      </PageLayout>
      );
  }

  renderBloc(iconTitleProps, itemList){
    return(
      <div className={style.content_bloc}>
        <IconTitle {...iconTitleProps}/>
        <ul className={style.list}>
          {itemList.map((item) => {
              return <li>{item.text}</li>
            })}
        </ul>
      </div>
    );
  }

}
  
export default PagePoste;