import React from "react";
import SlideLayout from "../../layouts/SlideLayout";
import SlideCard from "../items/SlideCard";
import * as style from "./slides.module.scss";


class PrestationSlide extends React.Component {
  render() {

    let properties = {
      slideNumber: "05",
      slideTitle: "contact",
      slideText: "Envoyez nous un mail à",
      mailText: "Contact@amg-it.fr"
    }

    return (
        <div>
          <SlideLayout>
            <div className={style.container}>
            <div className={style.content}>
              <SlideCard {...properties}/>
            </div>
            
            </div>
          </SlideLayout>
        </div>
    );
  }
}

export default PrestationSlide;