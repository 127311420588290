import React from "react";
import MetiersHeader from "../../../static/images/metiers_header.jpg";
import SocieteHeader from "../../../static/images/societe_header.jpg";
import * as style from "./PageHeader.module.scss";

class PageHeader extends React.Component {

  render() {        
    
    let metiersDiv = {
      backgroundImage: "url(" + MetiersHeader + ")"      
    };
    let societeDiv = {
      backgroundImage: "url(" + SocieteHeader + ")"      
    };
    let BGStyle;

    switch(this.props.background){
      case "metiers":
        BGStyle =  metiersDiv;
        this.link = "/metiers/" + this.props.title;
        break;
      case "societe":
        BGStyle =  societeDiv;
        this.link = "/" + this.props.title;
        break;
      default:
        BGStyle =  societeDiv;
        break;
    }

  
    return (
      <div className={style.container} style={BGStyle}>
        <a href={this.getLink()}><div className={style.title}>{this.props.title}</div></a>
        <div className={style.path}>{this.props.path}</div>
      </div>
      );
  }

  getLink(){
    switch(this.props.background){
      case "metiers":
        return "/metiers/" + this.props.title;
      default:
        return null;
    }
  }

}
  
export default PageHeader;
