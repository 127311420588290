import React from "react";
import { Accordion, Card, Button, Nav} from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css'
import * as style from "./SideMenu.module.scss";

class SideMenu extends React.Component {

  i=0;

  render() {
    return (
        <div className={style.container}>
          <Accordion className="flex-column">
            {this.props.items.map((item) => {return this.renderCard(item)})}           
          </Accordion> 
        </div>
    );
  }

  renderCard(item){
    this.i++
    return (
      <Card>
        <style type="text/css">
          {`

          .btn .btn-link {
            text-decoration: none;
          }

          .btn-link:hover {
            text-decoration: none;
          }
          .btn-link:active {
            text-decoration: none;
          }
          `}
        </style>

        <Card.Header className={style.menu}>
          <Accordion.Toggle as={Button} variant="link" eventKey={this.i}>
            <span className={style.menu_title}>{item.title}</span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={this.i} className={style.sub_menu}>
          <Card.Body>
            {this.renderCardBody(item)}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }

  renderCardBody(cardBody){
    return (
      <Nav className="mr-auto flex-column">
        {cardBody.items.map((item) => {return this.renderLink(item)})}
      </Nav>
    )
  }

  renderLink(link){
    return (
        <Nav.Link href={link.link}>{link.title}</Nav.Link>
    )
  }
}

export default SideMenu;
