import React from "react";
import * as style from "./IconText.module.scss";
import TeacherIcon from "../../../static/images/svg/teacher.inline.svg";
import MoneyIcon from "../../../static/images/svg/money.inline.svg";

class IconText extends React.Component {

  render() {
        
    return (
      <div className={style.container}>
        {this.renderIcon()}
        <div className={style.icon_title}>{this.props.text}</div>        
      </div>
      );
  }

  renderIcon(){
    switch(this.props.icon){
      case "teacher": 
        return <TeacherIcon className={style.icon_container}/>;
      case "money": 
        return <MoneyIcon className={style.icon_container}/>;
  
      default: return null;

    }
  }

}
  
export default IconText;