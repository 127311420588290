export const  metiers_menu = {
    type: "dropDown",
    title: "METIERS",
    items : [
      {
        type: "dropDown",
        title: "SUPPORT",
        items: [
          {
            type: "link",
            title: "Technicien Support Applicatif",
            link: "/metiers/support/technicien_support_applicatif"
          },
          {
            type: "link",
            title: "Coordinateur",
            link: "/metiers/support/coordinateur"
          }
        ]
      },
      {
        type: "dropDown",
        title: "TECHNIQUES",
        link: "/metiers/techniques",
        items: [
          {
            type: "link",
            title: "Responsable Technique",
            link: "/metiers/techniques/responsable_technique"
          },
          {
            type: "link",
            title: "Intégrateur / Packageur",
            link: "/metiers/techniques/integrateur_packageur"
          },
          {
            type: "link",
            title: "Architecte",
            link: "/metiers/techniques/architecte"
          },
          {
            type: "link",
            title: "Technicien Support",
            link: "/metiers/techniques/technicien_support"
          }
        ]
      },
      {
        type: "dropDown",
        title: "INGENIERIE",
        items: [
          {
            type: "link",
            title: "Ingénieur Réseaux",
            link: "/metiers/ingenierie/ingenieur_reseaux"
          },
          {
            type: "link",
            title: "Ingénieur Système",
            link: "/metiers/ingenierie/ingenieur_systeme"
          },
          {
            type: "link",
            title: "",
            link: ""
          }
        ]
      },
      {
        type: "dropDown",
        title: "ACCOMPAGNEMENT",
        items: [
          {
            type: "link",
            title: "Chef de Projet",
            link: "/metiers/accompagnement/chef_de_projet"
          },
          {
            type: "link",
            title: "Consultant",
            link: "/metiers/accompagnement/consultant"
          },
          {
            type: "link",
            title: "Assistant de Gestion",
            link: "/metiers/accompagnement/assistant_de_gestion"
          }
        ]
      },
      {
        type: "dropDown",
        title: "MANAGEMENT",
        items: [
          {
            type: "link",
            title: "Responsable technique d’agence",
            link: "/metiers/management/responsable_technique_agence"
          },
          {
            type: "link",
            title: "Manager",
            link: "/metiers/management/manager"
          },
          {
            type: "link",
            title: "Delivery Manager",
            link: "/metiers/management/delivery_manager"
          },
          {
            type: "link",
            title: "",
            link: "/metiers/management/"
          }
        ]
      },
      {
        type: "dropDown",
        title: "COMMERCE",
        items: [
          {
            type: "link",
            title: "Ingénieur d'Affaires",
            link: "/metiers/commerce/ingenieur_affaires"
          },
          {
            type: "link",
            title: "Ingénieur Commercial",
            link: "/metiers/commerce/ingenieur_commercial"
          },
          {
            type: "link",
            title: "",
            link: "/metiers/commerce/"
          }
        ]
      },
      {
        type: "dropDown",
        title: "RESSOURCES HUMAINES",
        items: [
          {
            type: "link",
            title: "Chargé de Recrutement",
            link: "/metiers/rh/charge_recrutement"
          },
          {
            type: "link",
            title: "",
            link: ""
          }
        ]
      }
    ]
  
      
  }
  
  let socitete_menu = {
    type: "dropDown",
    title: "SOCIETE",
    items : [
      {
        type: "link",
        title: "QUI SOMMES-NOUS?",
        link: "/societe/qui_nous_sommes"
      },
      {
        type: "link",
        title: "NOS VALEURS",
        link: "/societe/nos_valeurs"
      },
      {
        type: "link",
        title: "NOS EQUIPES",
        link: "/societe/nos_equipes"
      },
      {
        type: "link",
        title: "NOS IMPLANTATIONS",
        link: "/societe/nos_implantations"
      },
    ]
  }
  
export const main_menu = {
    items: [
      {
        type: "link",
        title: "ACCUEIL",
        link: "/"
      },
      socitete_menu,
      {
        type: "link",
        title: "PRESTATIONS",
        link: "/#prestations"
      },
      metiers_menu,
      {
        type: "link",
        title: "CONTACT",
        link: "/#contact"
      }
    ]
  
  }
