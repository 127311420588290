import React from "react";
import * as style from "./SlideCard.module.scss";

class SlideCard extends React.Component {

  render() {
        
    return (
      <div>
        <div className={style.card_number}>{this.props.slideNumber}</div>
        <div className={style.card_title}>{this.props.slideTitle}</div>
        {this.renderText()}
        
      </div>
      );
  }

  renderText(){
    if (this.props.slideText){
      if (this.props.mailText) 
        return <div className={style.card_text}>{this.props.slideText}: <a href="mailto:{this.props.mailText}">{this.props.mailText}</a></div>
      else 
        return <div className={style.card_text}>{this.props.slideText}</div>
    }
  }

}
  
export default SlideCard;