import React from "react";
import * as style from "./IconCard.module.scss";
import KeyHandIcon from "../../../static/images/svg/key_hand.inline.svg";
import PeopleIcon from "../../../static/images/svg/people.inline.svg";
import ChartIcon from "../../../static/images/svg/chart.inline.svg";

class IconCard extends React.Component {

  render() {
        
    return (
      <div className={style.container}>
        <div className={style.icon_container}>{this.renderIcon()}</div>
        <div className={style.icon_title}>{this.props.iconTitle}</div>        
      </div>
      );
  }

  renderIcon(){
    switch(this.props.icon){
      case "key_hand": 
        return <KeyHandIcon className={style.icon_container}/>;
      case "people": 
        return <PeopleIcon className={style.icon_container}/>;
      case "chart": 
        return <ChartIcon className={style.icon_container}/>;
  
      default: return null;

    }


  }

}
  
export default IconCard;