import React from "react";
import SlideLayout from "../../layouts/SlideLayout";
import SlideCard from "../items/SlideCard";
import IconItem from "../icons/IconItem";
import * as style from "./slides.module.scss";


class PrestationSlide extends React.Component {
  render() {
    
    let properties = {
      slideNumber: "03",
      slideTitle: "valeurs",
      slideText: "Nos valeurs !!"
    }

    let icons = [
      {
        icon: "satisfaction",
        iconTitle: "satisfaction client"
      },
      {
        icon: "epanouissement",
        iconTitle: "épanouissement professionnel"
      },
      {
        icon: "humain",
        iconTitle: "gestion de l'humain"
      }
    ];

    return (
        <div className={style.valeurBackground}>
          <SlideLayout>
            <div className={style.container}>
              <div className={style.content}>
                <SlideCard {...properties}/>
                <div className={style.icon_container}>
                  {icons.map((icon) => {
                    return <IconItem {...icon}/>
                  })}
                </div>
              </div>
            </div>
          </SlideLayout>
        </div>
    );
  }
}

export default PrestationSlide;