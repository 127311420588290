import React from "react";
import PageLayout from "../../layouts/PageLayout";
import ContentCard from "../items/ContentCard";
import IconCard from "../icons/IconCard";
import ListItem from "../items/ListItem";
import NousRejoindreBloc from "./NousRejoindreBloc";
import * as style from "./QuiNousSommesPage.module.scss";

class QuiNousSommesPage extends React.Component {

  render() {

    let item_cards = [
      {
        icon: "key_hand",
        iconTitle: "Date de Création 2014"
      },
      {
        icon: "people",
        iconTitle: "Effectif 50 collaborateurs"
      },
      {
        icon: "chart",
        iconTitle: "Chiffre d'affaires 2019 xxx"
      }
    ];
       
    return (
      <PageLayout {...this.props.header}>
        <div className={style.container_top}>
          <div className={style.content}>
            <div className={style.content_card} >
              <ContentCard {...this.props.content}/>
            </div>
          </div>
          <div className={style.band}>
            <div className={style.band_left}><div className={style.boss_image}/></div>
              <div className={style.band_right}>
                <div  className={style.boss}>
                <div className={style.boss_name}>{this.props.boss.name}</div>
                <ListItem {...this.props.boss.title}/>
                {this.props.boss.cv.map((item) => {
                  return <div className={style.boss_cv}>{item.text}</div>
                })
              }
              </div>
            </div>
          </div>
          <div className={style.content}>
              {this.props.presentations.map((presentation) => {
                return <div>{this.renderPresentation(presentation)}</div>
                }
              )}
          </div>
        </div>
        <div className={style.container_bottom}>
          <div className={style.content}>
              <div className={style.key_figures}>
                <div className={style.key_figures_title}>
                  Quelques chiffres clés
                </div>
                {item_cards.map((item) => {
                  return <IconCard {...item}/>
                  }
                )}
              </div>
          </div>
          <div className={style.content}>
            <NousRejoindreBloc/>
          </div>
        </div>
      </PageLayout>
      );
  }

  renderPresentation(presentation){
    return (
      <div className={style.presentation}>
        <div className={style.presentation_title}>
            {presentation.title}
        </div>
        <div>
          {presentation.list_items.map((item) => {
            return <ListItem {...item}/>
            }
          )}
        </div>
      </div>);
  }

}
  
export default QuiNousSommesPage;