import React from "react";
import * as style from "./ContentCard.module.scss";

class ContentCard extends React.Component {


  render() {        
    return (
      <div className={style.container}>
        <div className={style.category}>{this.props.category}</div>
        <div className={style.title}>{this.props.title}</div>
        <div className={style.subtitle}>{this.props.subtitle}</div>
        <div className={style.text}>{this.props.text}</div>
      </div>
      );
  }
}
  
export default ContentCard;
