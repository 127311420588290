import React from "react";
import * as style from "./ListItem.module.scss";

class SlideCard extends React.Component {

  render() {
        
    return (
        <div className={style.list_item}>{this.props.text}</div>
      );
  }

}
  
export default SlideCard;