import React from "react";
import * as style from "./CollaborateurMenu.module.scss";

class CollaborateurMenu extends React.Component {
  render() {
    return (
        <div className={style.container}>
            <div className={style.content}>
                <div>ESPACE COLLABORATEURS</div>
            </div>
        </div>
    );
  }
}

export default CollaborateurMenu;