import React from "react";
import SlideLayout from "../../layouts/SlideLayout";
import SlideCard from "../items/SlideCard";
import IconItem from "../icons/IconItem";
import * as style from "./slides.module.scss";


class PrestationSlide extends React.Component {
  render() {

    let properties = {
      slideNumber: "01",
      slideTitle: "prestations",
      slideText: "Nos prestations en lien avec vos besoins"
    }

    let icons = [
      {
        icon: "assistance",
        iconTitle: "assistance technique ou régie"
      },
      {
        icon: "infogerance",
        iconTitle: "infogérance ou forfait"
      },
      {
        icon: "projet",
        iconTitle: "gestion de projet"
      },
      {
        icon: "hebergement",
        iconTitle: "hébergement"
      }
    ];

    return (
        <div className={style.prestationBackground}>
          <SlideLayout>
            <div className={style.container}>
            <div className={style.content}>
              <SlideCard {...properties}/>
            
              <div className={style.icon_container}>
                {icons.map((icon) => {
                  return <IconItem {...icon}/>
                })}
              </div>
            </div>
          </div>
          </SlideLayout>
        </div>
    );
  }
}

export default PrestationSlide;