import React from "react";
import SlideLayout from "../../layouts/SlideLayout";
import SlideCard from "../items/SlideCard";
import ItemCard from "../items/ItemCard";
import * as style from "./slides.module.scss";


class MetiersSlide extends React.Component {
  render() {

    let properties = {
      slideNumber: "02",
      slideTitle: "métiers"
    }

    let cards = [
      {
        cardText: "support",
        cardLink: "/metiers/techniques"
      },
      {
        cardText: "technique",
        cardLink: "/metiers/techniques"
      },
      {
        cardText: "ingénierie",
        cardLink: "/metiers/techniques"
      },
      {
        cardText: "accompagnement",
        cardLink: "/metiers/techniques"
      },
      {
        cardText: "management",
        cardLink: "/metiers/techniques"
      },
      {
        cardText: "commerce",
        cardLink: "/metiers/techniques"
      },
      {
        cardText: "ressources humaines",
        cardLink: "/metiers/techniques"
      }
    ]

    return (
        <div className={style.metierBackground}>
          <SlideLayout>
            <div className={style.container}>
            <div className={style.content}>
            <SlideCard {...properties}/>
            <div className={style.icon_container}>
                  {cards.map((card) => {
                    return <ItemCard {...card} className={style.icon_item} />
                  })}
                </div>
            </div>
            </div>
          </SlideLayout>
        </div>
    );
  }
}

export default MetiersSlide;