import React from "react";
import SwiperCore, { Navigation, Pagination, Mousewheel, Scrollbar, Keyboard} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LandingSlide from "../components/slides/LandingSlide";
import PrestationSlide from "../components/slides/PrestationSlide";
import MetierSlide from "../components/slides/MetiersSlide";
import ValeurSlide from "../components/slides/ValeurSlide";
import PosteSlide from "../components/slides/PosteSlide";
import ContactSlide from "../components/slides/ContactSlide";
import * as style from "./layout.module.scss";


import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import "../scss/custom.scss";

SwiperCore.use([Navigation, Pagination, Mousewheel, Scrollbar, Keyboard]);

class HomeLayout extends React.Component {
  render() {

   return (
      <div>      
      <Swiper 
        direction='vertical'
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination
        mousewheel={{ enabled: true}}
        spaceBetween={0}
        scrollbar={{ draggable: true }}
        keyboard={{ enabled: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        className={style.container}>
  
        <SwiperSlide class="swiper-slide" className={style.slide}><LandingSlide/></SwiperSlide>
        <SwiperSlide id="prestations" class="swiper-slide" className={style.slide}><PrestationSlide/></SwiperSlide>
        <SwiperSlide id="metiers" class="swiper-slide" className={style.slide}><MetierSlide/></SwiperSlide>
        <SwiperSlide id="valeurs" class="swiper-slide" className={style.slide}><ValeurSlide/></SwiperSlide>
        <SwiperSlide id="postes" class="swiper-slide" className={style.slide}><PosteSlide/></SwiperSlide>
        <SwiperSlide id="contact" class="swiper-slide" className={style.slide}><ContactSlide/></SwiperSlide>
  
      </Swiper>
  
    </div>
    );
  }
}

export default HomeLayout;



/*export default ({ children }) => (
  <div>      
    <Swiper 
      direction='vertical'
      navigation
      pagination
      mousewheel='true'
      slidesPerView={1}
      spaceBetween={0}
      scrollbar={{ draggable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}>

      <SwiperSlide><LandingSlide/></SwiperSlide>
      <SwiperSlide><PrestationSlide/></SwiperSlide>
      <SwiperSlide><MetierSlide/></SwiperSlide>
      <SwiperSlide><ValeurSlide/></SwiperSlide>
      <SwiperSlide><PosteSlide/></SwiperSlide>
      <SwiperSlide><ContactSlide/></SwiperSlide>

    </Swiper>

  </div>
);*/


/*


                <LandingSlide/>
      <PrestationSlide/>
      <MetierSlide/>
      <ValeurSlide/>
      <PosteSlide/>
      <ContactSlide/>

          */