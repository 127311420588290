import React from "react";
import { Navbar, Nav, NavDropdown} from 'react-bootstrap';
import {main_menu} from "./MenuData";
import LogoIcon from "../../../static/images/svg/logo.inline.svg";

import 'bootstrap/dist/css/bootstrap.min.css'
import * as style from "./Menu.module.scss";

class Menu extends React.Component {
  render() {
    return (
        <div className={style.container}>
        <div className={style.logo_container}><LogoIcon className={style.logo}/></div>
        <div className={style.content}>
          <Navbar expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                {main_menu.items.map((item) => {return this.renderItem(item)})}
              </Navbar.Collapse>           
          </Navbar> 
          </div>
        </div>
    );
  }


  renderItem(item){
    if (item.type === "link")
      return this.renderLink(item)
    if (item.type === "dropDown")
      return this.renderDropDown(item)
  }

  renderDropDown(dropDown){
    return (
      <NavDropdown title={dropDown.title} id="basic-nav-dropdown">
        {dropDown.items.map((item) => {return this.renderItem(item)})}
      </NavDropdown>
    )
  }

  renderLink(link){
    return (
      <Nav className="mr-auto">
        <Nav.Link href={link.link}>{link.title}</Nav.Link>
      </Nav>
    )
  }

}

export default Menu;

/*<div className={style.content}> 
              <div className={style.item}>ACCEUIL</div>
              <div className={style.item}>PRESTATIONS</div>
              <div className={style.item}>METIERS</div>
              <div className={style.item}>SOCIETE</div>
              <div className={style.item}>ON RECRUTE !</div>
              <div className={style.item}>CONTACT</div>
            </div>*/

/*
<Nav className="mr-auto">
                  <Nav.Link href="/">ACCUEIL</Nav.Link>
                </Nav>
                <Nav className="mr-auto">
                  <Nav.Link href="/">PRESTATIONS</Nav.Link>
                </Nav>
                <MetiersMenu/>
                <NavDropdown title="SOCIETE" id="basic-nav-dropdown">
                  <Nav className="mr-auto">
                    <Nav.Link href="/societe/qui_nous_sommes">QUI SOMMES-NOUS?</Nav.Link>
                  </Nav>
                  <Nav className="mr-auto">
                    <Nav.Link href="/societe/nos_valeurs">NOS VALEURS</Nav.Link>
                  </Nav>
                  <Nav className="mr-auto">
                    <Nav.Link href="/">NOS EQUIPES</Nav.Link>
                  </Nav>
                  <Nav className="mr-auto">
                    <Nav.Link href="/">NOS IMPLANTATIONS</Nav.Link>
                  </Nav>
                </NavDropdown>
                <Nav className="mr-auto">
                  <Nav.Link href="/">ON RECRUTE !</Nav.Link>
                </Nav>
                <Nav className="mr-auto">
                  <Nav.Link href="/">CONTACT</Nav.Link>
                </Nav>*/