import React from "react";
import * as style from "./FichePoste.module.scss";

class FichePoste extends React.Component {

  render() {
        
    return (
      <div className={style.container}>
        <div className={style.header}/>
        <div className={style.content}>
          <a href={this.props.link}>
            <div className={style.title}>
              {this.props.title}
            </div>
          </a>
          <div className={style.text}>
            {this.props.text}
          </div>
        </div>
      </div>
      );
  }

}
  
export default FichePoste;