import React from "react";
import * as style from "./ItemCard.module.scss";

class SlideCard extends React.Component {

  render() {
        
    return (
        <a href={this.props.cardLink}>
          <div className={style.card}>
            <div className={style.card_text}>{this.props.cardText}</div>
          </div>
        </a>
      );
  }

}
  
export default SlideCard;