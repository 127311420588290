import React from "react";
import * as style from "./IconTitle.module.scss";
import KeySkillsIcon from "../../../static/images/svg/key_skills.inline.svg";
import PrerequisistesIcon from "../../../static/images/svg/prerequisites.inline.svg";
import MissionsIcon from "../../../static/images/svg/missions.inline.svg";
import ActivitiesIcon from "../../../static/images/svg/activities.inline.svg";

class IconItem extends React.Component {

  render() {
        
    return (
      <div className={style.container}>
        {this.renderIcon()}
        <div className={style.icon_title}>{this.props.iconTitle}</div>        
      </div>
      );
  }

  renderIcon(){
    switch(this.props.icon){
      case "key_skills": 
        return <KeySkillsIcon className={style.icon_container}/>;
      case "prerequisites": 
        return <PrerequisistesIcon className={style.icon_container}/>;
      case "missions": 
        return <MissionsIcon className={style.icon_container}/>;
      case "activities": 
        return <ActivitiesIcon className={style.icon_container}/>;
  
      default: return null;

    }


  }

}
  
export default IconItem;