import React from "react";
import PageLayout from "../../layouts/PageLayout";
import ContentCard from "../items/ContentCard";
import PhotoCard from "../items/PhotoCard";
import NousRejoindreBloc from "./NousRejoindreBloc";
import * as style from "./NosEquipesPage.module.scss";

class NosValeurs extends React.Component {

  render() {
    
    return (
      <PageLayout {...this.props.header}>
        <div className={style.container_top}>
          <div className={style.content}>
            <div className={style.content_card} >
              <ContentCard {...this.props.content}/>
            </div>
          </div>
          <div>
            {this.props.collaborateurs.map((collaborateur) => {
                  return <PhotoCard {...collaborateur}/>
                  }
                )}
          </div>
        </div>
        <div className={style.container_bottom}>
          <div className={style.content}>
            <NousRejoindreBloc/>
          </div>
        </div>
        
      </PageLayout>
      );
  }

}
  
export default NosValeurs;