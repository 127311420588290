import React from "react";
import * as style from "./IconItem.module.scss";
import AssistanceIcon from "../../../static/images/svg/assistance.inline.svg";
import HebergementIcon from "../../../static/images/svg/hebergement.inline.svg";
import InfogeranceIcon from "../../../static/images/svg/infogerance.inline.svg";
import ProjetIcon from "../../../static/images/svg/projet.inline.svg";
import SatisfactionIcon from "../../../static/images/svg/satisfaction.inline.svg";
import EpanouissementIcon from "../../../static/images/svg/epanouissement.inline.svg";
import HumainIcon from "../../../static/images/svg/humain.inline.svg";

class IconItem extends React.Component {

  render() {
        
    return (
      <div className={style.container}>
        <div className={style.icon_container}>{this.renderIcon()}</div>
        <div className={style.icon_title}>{this.props.iconTitle}</div>        
      </div>
      );
  }

  renderIcon(){
    switch(this.props.icon){
      case "assistance": 
        return <AssistanceIcon className={style.icon_container}/>;
      case "hebergement": 
        return <HebergementIcon className={style.icon_container}/>;
      case "infogerance": 
        return <InfogeranceIcon className={style.icon_container}/>;
      case "projet": 
        return <ProjetIcon className={style.icon_container}/>;
      case "satisfaction": 
        return <SatisfactionIcon className={style.icon_container}/>;
      case "epanouissement": 
        return <EpanouissementIcon className={style.icon_container}/>;
      case "humain": 
        return <HumainIcon className={style.icon_container}/>;
  
      default: return null;

    }


  }

}
  
export default IconItem;