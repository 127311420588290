import React from "react";
import ListItem from "./ListItem";
import * as style from "./PhotoCard.module.scss";

class PhotoCard extends React.Component {

  render() {        
    return (
      <div className={style.presentation}>
        <div className={style.presentation_title}>
            {this.props.title}
        </div>
        <div className={style.list}>
          <ListItem {...this.props}/>
        </div>
      </div>
      );
  }

}
  
export default PhotoCard;
