import React from "react";
import CollaborateurMenu from "../components/menus/CollaborateurMenu";
import Menu from "../components/menus/Menu";
import "../scss/custom.scss";

export default ({ children }) => (
    <div>
        <CollaborateurMenu/>
        <Menu/>
      {children}
    </div>
);
