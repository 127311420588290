// extracted by mini-css-extract-plugin
export const title = "PagePoste-module--title--1nzeb";
export const descriptif = "PagePoste-module--descriptif--2PHUP";
export const content_col2 = "PagePoste-module--content_col2--3IcZH";
export const content_col1 = "PagePoste-module--content_col1--qWhL_";
export const container = "PagePoste-module--container--2mjF3";
export const main_container = "PagePoste-module--main_container--iQAPO";
export const descriptif_container = "PagePoste-module--descriptif_container--3RnPm";
export const content = "PagePoste-module--content--39lmG";
export const content_header = "PagePoste-module--content_header--1Ptn5";
export const list = "PagePoste-module--list--2EjQZ";
export const content_bloc = "PagePoste-module--content_bloc--SCJs9";
export const side_menu = "PagePoste-module--side_menu--22ZJZ";
export const cream_bloc = "PagePoste-module--cream_bloc--1qD0C";