import React from "react";
import ContentCard from "../items/ContentCard";
import PageLayout from "../../layouts/PageLayout";
import FichePoste from "./FichePoste";
import * as style from "./PageMetier.module.scss";
import SideMenu from "../menus/SideMenu";
import { metiers_menu } from "../menus/MenuData";

class PageMetier extends React.Component {

  render() {

    return (
      <PageLayout {...this.props.header}>
        <div className={style.container}>
          <div className={style.content}>
            <div className={style.content_card} >
              <ContentCard {...this.props.content}/>
            </div>
            <div className={style.side_menu} >
              <SideMenu {... metiers_menu}/>
            </div>
            <div className={style.fiches} >
              {this.props.fiches.map((fiche) => {
                return <FichePoste {...fiche}/>
              })}            
            </div>
          </div>
        </div>
      </PageLayout>
      );
  }

}
  
export default PageMetier;