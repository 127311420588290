// extracted by mini-css-extract-plugin
export const key_figures_title = "QuiNousSommesPage-module--key_figures_title--3HUXr";
export const presentation_title = "QuiNousSommesPage-module--presentation_title--1ohqu";
export const boss_cv = "QuiNousSommesPage-module--boss_cv--1MiD5";
export const boss_name = "QuiNousSommesPage-module--boss_name--FWsIF";
export const container = "QuiNousSommesPage-module--container--16rfS";
export const container_bottom = "QuiNousSommesPage-module--container_bottom--3YxS0";
export const container_top = "QuiNousSommesPage-module--container_top--2MepX";
export const content = "QuiNousSommesPage-module--content--2uCy7";
export const content_card = "QuiNousSommesPage-module--content_card--11MHZ";
export const band = "QuiNousSommesPage-module--band--1lGk2";
export const band_left = "QuiNousSommesPage-module--band_left--GKAp4";
export const boss_image = "QuiNousSommesPage-module--boss_image--2Tj6k";
export const band_right = "QuiNousSommesPage-module--band_right--3dfDu";
export const boss = "QuiNousSommesPage-module--boss--2TAV7";
export const presentation = "QuiNousSommesPage-module--presentation--3Wfgx";
export const key_figures = "QuiNousSommesPage-module--key_figures--2Aw3Y";