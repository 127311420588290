// extracted by mini-css-extract-plugin
export const quoted_text = "NosValeursPage-module--quoted_text--2EMFg";
export const presentation = "NosValeursPage-module--presentation--1PBZy";
export const container = "NosValeursPage-module--container--1A4EE";
export const container_bottom = "NosValeursPage-module--container_bottom--3PPOU";
export const container_top = "NosValeursPage-module--container_top--19jzV";
export const content = "NosValeursPage-module--content--1zzH3";
export const content_card = "NosValeursPage-module--content_card--2oCFR";
export const band = "NosValeursPage-module--band--1yogo";
export const band_left = "NosValeursPage-module--band_left--3ievK";
export const band_right = "NosValeursPage-module--band_right--1gYbN";
export const end_quote_icon = "NosValeursPage-module--end_quote_icon--wNAZd";
export const start_quote_icon = "NosValeursPage-module--start_quote_icon--1Xc63";
export const content_valeurs = "NosValeursPage-module--content_valeurs---FbNa";
export const valeurs = "NosValeursPage-module--valeurs--3lagQ";
export const image_container = "NosValeursPage-module--image_container--1eYNK";
export const motivation_image = "NosValeursPage-module--motivation_image--zrEbC";