// extracted by mini-css-extract-plugin
export const credentials = "PageFooter-module--credentials--GWlaS";
export const search = "PageFooter-module--search--2RuL7";
export const col4 = "PageFooter-module--col4--AtJlB";
export const col3 = "PageFooter-module--col3--GAswP";
export const col2 = "PageFooter-module--col2--1t0pq";
export const col1 = "PageFooter-module--col1--_Dztw";
export const container = "PageFooter-module--container--3N3AD";
export const container_top = "PageFooter-module--container_top--2hEeo";
export const content = "PageFooter-module--content--3rW57";
export const logo = "PageFooter-module--logo--2EyLR";
export const rest = "PageFooter-module--rest--2LYpo";
export const container_bottom = "PageFooter-module--container_bottom--M6Ori";