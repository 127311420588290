import React from "react";
import PageLayout from "../../layouts/PageLayout";
import ContentCard from "../items/ContentCard";
import StartQuoteIcon from "../../../static/images/svg/start_quote.inline.svg";
import EndQuoteIcon from "../../../static/images/svg/end_quote.inline.svg";
import IconText from "../icons/IconText";
import NousRejoindreBloc from "./NousRejoindreBloc";
import * as style from "./NosValeursPage.module.scss";

class NosValeurs extends React.Component {

  render() {
    
    return (
      <PageLayout {...this.props.header}>
        <div className={style.container_top}>
          <div className={style.content}>
            <div className={style.content_card} >
              <ContentCard {...this.props.content}/>
            </div>
          </div>
          <div className={style.band}>
            <div className={style.band_left}></div>
            <div className={style.band_right}>
              <div className={style.quoted_text}>
                <StartQuoteIcon className={style.start_quote_icon}/>
                <div>{this.props.band_text}</div>
                <EndQuoteIcon className={style.end_quote_icon}/>
              </div>
            </div>
          </div>
          <div className={style.content_valeurs}>
              
            <p className={style.valeurs}>
              <div className={style.presentation}>
                {this.props.valeurs.presentation_text}
              </div>
              <div className={style.image_container}>
                <div className={style.motivation_image}/>
              </div> 
              <div>
                {this.props.valeurs.blocs.map((bloc) => {
                  return <IconText {...bloc}/>
                  }
                )}
              </div>
            </p>
          </div>
        </div>
        <div className={style.container_bottom}>
          <div className={style.content}>
            <NousRejoindreBloc/>
          </div>
        </div>
        
      </PageLayout>
      );
  }

}
  
export default NosValeurs;