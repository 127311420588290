import React from "react";
import SlideLayout from "../../layouts/SlideLayout";
import SlideCard from "../items/SlideCard";
import * as style from "./slides.module.scss";


class PrestationSlide extends React.Component {
  render() {

    
    let properties = {
      slideNumber: "04",
      slideTitle: "postes à pourvoir"
    }
    return (
        <div className={style.posteBackground}>
          <SlideLayout>
            <div className={style.container}>
            <div className={style.content}>
            <SlideCard {...properties}/>
            </div>
            </div>
          </SlideLayout>
        </div>
    );
  }
}

export default PrestationSlide;