import React from "react";
import LogoIcon from "../../../static/images/svg/logo.inline.svg";
import * as style from "./PageFooter.module.scss";

class PageFooter extends React.Component {


  render() {        
    return (
      <div>
        <div className={style.container_top}>
          <div className={style.content}>
            <div className={style.logo}>
              <LogoIcon/>
            </div>
            <div className={style.col1}>
              <div>{this.renderCell("/","AMG-IT")}</div>
              <div>187 Terrasse de l'Université</div>
              <div>92000 NANTERRE</div>
              <div></div>
            </div>
            <div className={style.col2}>
              <div><a href="/#postes">Nos Offres</a></div>
              <div><a href="/metiers/support">Support</a></div>
              <div><a href="/metiers/technique">Technique</a></div>
              <div><a href="/metiers/ingenierie">Ingénierie</a></div>
            </div>
            <div className={style.col3}>
              <div><a href="/metiers/accompagnement">Accompagnement</a></div>
              <div><a href="/metiers/management">Management</a></div>
              <div><a href="/metiers/commerce">Commerce</a></div>
              <div><a href="/metiers/rh">Resources Humaines</a></div>
            </div>
            
            <div className={style.search}><a href="/#postes">ON RECRUTE!</a></div>
            <div className={style.col4}></div>
          </div>
          

        </div>
        <div className={style.container_bottom}>
            <div className={style.credentials}>© 2020 - AMG-IT   |   Tous droits réservés | Mentions légales   |   Politique de confidentialité 
            </div>
        </div>
      </div>
      );
  }

  renderCell(url, text){
    return (
      <div><a href={url}>{text}</a></div>
    );
  }

}
  
export default PageFooter;
