import React from "react";
import * as style from "./slides.module.scss";

class LandingSlide extends React.Component {
  render() {
    return (
        <div className={style.landingBackground}>
        </div>
    );
  }
}

export default LandingSlide;