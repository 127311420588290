import React from "react";
import * as style from "./NousRejoindreBloc.module.scss";

class NousRejoindreBloc extends React.Component {

  render() {
    
    return (
      <div className={style.container}>
        <div className={style.small}>
          Juniors, seniors, envie de grandir avec nous ?
        </div>
        <div className={style.medium}>
          Rejoignez notre équipe !
        </div>
        <a href="/#contact">
          <div className={style.button}>
            <div className={style.button_text}>Je prends contact</div>
          </div>
        </a>
      </div>
      );
  }

}

export default NousRejoindreBloc;