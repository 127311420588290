// extracted by mini-css-extract-plugin
export const container = "slides-module--container--3Io_N";
export const content = "slides-module--content--3hcVI";
export const icon_container = "slides-module--icon_container--1aleY";
export const icon_item = "slides-module--icon_item--1qJfK";
export const posteBackground = "slides-module--posteBackground--2fykK";
export const valeurBackground = "slides-module--valeurBackground--zHDNc";
export const metierBackground = "slides-module--metierBackground--3npyx";
export const prestationBackground = "slides-module--prestationBackground--2h0h3";
export const landingBackground = "slides-module--landingBackground--3elZw";
export const down_button = "slides-module--down_button--2ieAr";