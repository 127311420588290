import React from "react";
import CollaborateurMenu from "../components/menus/CollaborateurMenu";
import PageHeader from "../components/pages/PageHeader"
import PageFooter from "../components/pages/PageFooter"
import Menu from "../components/menus/Menu";
import "../scss/custom.scss";

class PageLayout extends React.Component {
  
  render() {

    const {children} = this.props;
    console.log("Page Layout:" + this.props.background);

    return (
      <div>
          <CollaborateurMenu/>
          <Menu/>
          <PageHeader {...this.props}/>
          {children}
          <PageFooter/>
  
      </div>
  );
  }

}

export default PageLayout;